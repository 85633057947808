import { useContext, useEffect, useRef, useState, type FC } from "react";
import { LoadingContext } from "../../objects/loading_context";
import { useNavigate, useParams } from "react-router-dom";
import { GetProfileResponseData } from "../../grpc/client/client_general_pb";
import CommentatorMid from "../../middlewares/CommentatorMid";
import Back from "../../components/back";
import {
  GetOrderDetailRequest,
  GetOrderDetailResponseData,
  GetOrderDiscussionRequest,
  GetOrderDiscussionResponseData,
  SendDiscussionRequest,
  SetAsInProgressRequest,
} from "../../grpc/commentator/commentator_order_pb";
import { commentatorAccountClient, commentatorOrderClient } from "../../App";
import { getToken, money, numberToDuration } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/helper-ui";
import { HiOutlineClock, HiPaperClip } from "react-icons/hi2";
import moment from "moment";
import { PiSmiley, PiTriangleFill } from "react-icons/pi";
import { IoMdSend } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import ChatBubble from "../../components/ChatBubble";
import { modalDialogPropTypes } from "rsuite/esm/Modal/ModalDialog";
import { Checkbox, Divider, Modal } from "rsuite";
import { FaTriangleExclamation } from "react-icons/fa6";
import {
  GetDetailCommentatorAccountRequest,
  GetDetailCommentatorAccountResponseData,
} from "../../grpc/commentator/commentator_account_pb";
import { DONE, IN_PROGRESS,  TO_DO } from "../../utils/contants";
import ChatBox from "../../components/ChatBox";

interface CommentatorOrderDetailProps {}

const CommentatorOrderDetail: FC<CommentatorOrderDetailProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { orderId, accountId } = useParams();
  const nav = useNavigate();
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState<
    GetOrderDetailResponseData.AsObject | null | undefined
  >(null);
  const [isFinished, setIsFinished] = useState(false);
  const [chatBoxEnabled, setChatBoxEnabled] = useState(true);
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const [modalEmoji, setModalEmoji] = useState(false);
  const [msg, setMsg] = useState("");
  const [activeDiscussion, setActiveDiscussion] = useState("client");
  const [msgCommentators, setMsgCommentators] = useState<
    GetOrderDiscussionResponseData.AsObject[]
  >([]);
  const [modalDoOpen, setModalDoOpen] = useState(false);
  const [account, setAccount] =
    useState<GetDetailCommentatorAccountResponseData.AsObject | null>(null);
  const [accountName, setAccountName] = useState("");

  const [modalPresence, setModalPresence] = useState(false);
  const [isAccountChecked, setIsAccountChecked] = useState(false);
  const [isPositiveChecked, setIsPositiveChecked] = useState(false);
  const [isNegativeChecked, setIsNegativeChecked] = useState(false);
  const [isTagFriendChecked, setIsTagFriendChecked] = useState(false);
  const [isLikeChecked, setIsLikeChecked] = useState(false);
  const [isShareChecked, setIsShareChecked] = useState(false);
  const [labelStatus, setLabelStatus] = useState("");

  const statusLabels : Record<string, string> = {
    
    [TO_DO]: "Untuk Dikerjakan",
    [IN_PROGRESS]: "Dalam Proses",
    [DONE]: "Sudah Dikerjakan",
};
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getDetail();
    getDiscussions();
    getAccountDetail();
    // getSuspicious()
  }, [mounted, accountId]);

  const getAccountDetail = () => {
    if (!accountId) return;
    setIsLoading(true);
    let req = new GetDetailCommentatorAccountRequest();
    req.setId(accountId!);
    commentatorAccountClient.getDetailCommentatorAccount(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setAccount(resp.getData()?.toObject() ?? null);
        //   setProfile(resp);
      }
    );
  };

  useEffect(() => {
    if (account && data) {
        
        setLabelStatus(statusLabels[data?.status])
      switch (data?.platform) {
        case "x.com":
        case "twitter":
          setAccountName(account?.idTwitter);
          break;
        case "instagram":
          setAccountName(account?.idInstagram);
          break;
        case "tiktok":
          setAccountName(account?.idTiktok);
          break;
        case "youtube":
          setAccountName(account?.idYoutube);
          break;
        case "facebook":
          setAccountName(account?.idFacebook);
          break;
        case "detik":
          setAccountName(account?.idDetik);
          break;
        default:
          break;
      }
    }
  }, [account, data]);

  const getDetail = async () => {
    try {
      setIsLoading(false);

      let resp: GetOrderDetailResponseData.AsObject = await new Promise(
        (resolve, reject) => {
          let req = new GetOrderDetailRequest();
          req.setId(orderId!);
          req.setCommentatorAccountId(accountId!);
          commentatorOrderClient.getOrderDetail(
            req,
            getToken(),
            (err, resp) => {
              if (err) {
                reject(err.message);
                return;
              }
              resolve(resp.getData()?.toObject()!);
            }
          );
        }
      );
      setData(resp);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessage = async (message: string, filePath?: string | null) => {
    setIsLoading(true);
    let req = new SendDiscussionRequest();
    req.setOrderId(orderId!);
    req.setComment(message);
    req.setAttachmentPath(filePath ?? "");
    req.setAttachmentPathType(filePath ? "firebase" : "");
    // if (activeDiscussion == "client") {
    //     if (msgClients.length) {
    //         let last = msgClients[msgClients.length - 1]
    //         req.setParentOrderDiscussionId(last.id)
    //     }

    // }

    commentatorOrderClient.sendDiscussion(req, getToken(), (err, resp) => {
        setIsLoading(false);
      if (err) {
        errorToast(err.message);
        return;
      }
      setMsg("");
      getDiscussions();
    });
  };

  const getDiscussions = async () => {
    try {
      setIsLoading(false);
      let toCommentator = await getConversation();

      toCommentator.reverse();

      setMsgCommentators(toCommentator);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getConversation = async (): Promise<
    GetOrderDiscussionResponseData.AsObject[]
  > => {
    return await new Promise((resolve, reject) => {
      let req = new GetOrderDiscussionRequest();
      req.setOrderId(orderId!);
      commentatorOrderClient.getOrderDiscussion(
        req,
        getToken(),
        (err, resp) => {
          if (err) {
            reject(err.message);
            return;
          }
          resolve(resp.getDataList().map((e) => e.toObject()));
        }
      );
    });
  };

  const renderDiscussions = (data: GetOrderDiscussionResponseData.AsObject[]) =>
    data.map((e) => (
      <div className="w-full" key={e.id}>
        <ChatBubble profile={profile} data={e} />
        {e.repliesList.map((r) => (
          <div key={r.id}>
            <ChatBubble profile={profile} data={r} />
          </div>
        ))}
      </div>
    ));

  return (
    <CommentatorMid
      header={
        <div className="w-full px-4 py-2 justify-between items-center flex pt-6 bg-white">
          <Back />
          <h6>Detail Pesanan</h6>
          <div className="p-2 "></div>
        </div>
      }
      onLoadProfile={setProfile}
      noPadding
      hideMenu
    >
      <div className="flex flex-col h-full">
        <div className="flex-1  overflow-y-auto">
          <div className="bg-white px-4 py-8 mb-2">
            <div className="flex">
              <div className="w-2/12">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/assets/logo.svg";
                  }}
                  src={data?.thumbnailUrl}
                  alt=""
                  className=" object-cover w-10 h-10 rounded-lg bg-gray-50 mr-2"
                />
              </div>
              <div className="w-10/12 truncate">
                <h6>{data?.title}</h6>
                <p className=" truncate cursor-pointer">{data?.url}</p>
              </div>
            </div>
            <div className="flex border-b pb-4"></div>
            <div className=" flex justify-between mt-4 mb-2 items-center">
              <p className=" font-bold text-lg">Status</p>
              {!data?.finishedAt && (
                <span className=" text-sky-400 font-semibold">{labelStatus}</span>
              )}
            </div>
            <div className=" justify-between flex mb-2">
              <span>Sisa Waktu</span>
              <span className="flex text-orange-400 items-center">
                <HiOutlineClock size={18} className="mr-2" />
                {numberToDuration(
                  moment.utc().diff(moment.utc(data?.dueDate), "minutes")
                )}
              </span>
            </div>
            <div className=" justify-between flex mb-2">
              <span>Client</span>
              <span> {data?.user?.name}</span>
            </div>
            {/* 
            <div className=" justify-between flex mb-2">
              <span>Catatan</span>
            </div>
            <div className=" border border-gray-150 px-12 py-4 rounded-xl text-center">
              {data?.remarks}
            </div> */}
            <h1>
            
            </h1>
            {data?.status !== DONE && (
              <div className="flex justify-between mt-8">
                <button
                  className="btn-orange-pills"
                  onClick={() => data?.status === TO_DO ? setModalDoOpen(true) : errorToast("Task ini sedang dikerjakan, tekan tombol absen apabila anda sudah melakukan pekerjaan")}
                >
                  Kerjakan
                </button>
                <button
                  className="btn-orange"
                  onClick={() => data?.status === IN_PROGRESS ? setModalPresence(true) : errorToast("Tekan tombol kerjakan terlebih dahulu")}
                >
                  Absen
                </button>
              </div>
            )}
          </div>

          <div className="bg-white  mb-2 mt-2 min-h-[300px]">
            <p className={`  font-bold text-lg border-b p-4`}>Komentar</p>
            <div className="px-4 py-4 w-full flex flex-col">
              {renderDiscussions(msgCommentators)}
            </div>
          </div>
        </div>

        {/* CHATBOX */}
        {chatBoxEnabled && !isFinished && (
          <ChatBox sendMessage={sendMessage} />
        )}
      </div>
      <Modal
        size={"xs"}
        open={modalDoOpen}
        onClose={() => setModalDoOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Detail Pesanan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="-m-5">
          <div className="bg-red-50 py-2 px-4 flex flex-row gap-2 ">
            <FaTriangleExclamation className="text-yellow-300" />
            <p>
              Gunakan akun <span className="font-bold">{data?.platform}</span>{" "}
              {accountName} agar absen dapat terhitung otomatis
            </p>
          </div>

          <div className="p-4">
            <h5>Komentar</h5>
            <Divider />
            <div className="grid grid-cols-3 gap-4">
              <div className="text-center flex-col flex justify-center">
                <span className="">Positif</span>
                <span className="font-semibold text-xl mt-2">
                  {money(data?.positiveComment)}
                </span>
              </div>
              <div className="text-center flex-col flex justify-center">
                <span className="">Negatif</span>
                <span className="font-semibold text-xl mt-2">
                  {money(data?.negativeComment)}
                </span>
              </div>
              <div className="text-center flex-col flex justify-center">
                <span className="">Tag Teman</span>
                <span className="font-semibold text-xl mt-2">
                  {money(data?.tagFriend)}
                </span>
              </div>
            </div>
            <Divider />
            <h5 className="mb-4">Like & Shares</h5>
            <div className="flex flex-row gap-4">
              <div className="p-2 border rounded-md flex flex-row items-center gap-2">
                <img src="/assets/Heart.png" alt="" />
                <p className="font-semibold"> {money(data?.like)}</p>
              </div>
              <div className="p-2 border rounded-md flex flex-row items-center gap-2">
                <img src="/assets/share.png" alt="" />
                <p className="font-semibold"> {money(data?.share)}</p>
              </div>
            </div>
            <h5 className="my-4">Permintaan Klien</h5>
            <div className="p-4 rounded-lg bg-gray-100">{data?.remarks}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-orange w-full"
            onClick={() => {
              setIsLoading(true);
              let req = new SetAsInProgressRequest();
              req.setOrderId(orderId!);
              req.setCommentatorAccountId(accountId!);
              commentatorOrderClient.setAsInProgress(
                req,
                getToken(),
                (err, resp) => {
                  setIsLoading(false);
                  if (err) {
                    errorToast(err.message);
                    return;
                  }
                  getDetail();
                  setModalDoOpen(false);
                  window.open(data?.url);
                }
              );
            }}
          >
            Kunjungi Link
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size={"xs"}
        open={modalPresence}
        onClose={() => setModalPresence(false)}
      >
        <Modal.Header>
          <Modal.Title>Pesanan Sudah Dikerjakan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="-m-5">
          <div className="bg-red-50 py-2 px-4 flex flex-row gap-2 ">
            <FaTriangleExclamation className="text-yellow-300" />
            <p>Pastikan pesanan benar-benar sudah dikerjakan</p>
          </div>
          <div className="p-4">
            <div className="bg-gray-50 rounded-lg justify-between flex flex-row px-4 my-2 gap-4 items-center">
              <p>Akun</p>
              <div className="flex flex-row gap-2 justify-between items-center">
                <span>{accountName}</span>
                <Checkbox
                  checked={isAccountChecked}
                  onChange={() => setIsAccountChecked(!isAccountChecked)}
                />
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg justify-between flex flex-row px-4 my-2 gap-4 items-center">
              <p>Komentar Positif</p>
              <div className="flex flex-row gap-2 justify-between items-center">
                <span>{money(data?.positiveComment)}</span>
                <Checkbox
                  checked={isPositiveChecked}
                  onChange={() => setIsPositiveChecked(!isPositiveChecked)}
                />
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg justify-between flex flex-row px-4 my-2 gap-4 items-center">
              <p>Komentar Negatif</p>
              <div className="flex flex-row gap-2 justify-between items-center">
                <span>{money(data?.negativeComment)}</span>
                <Checkbox
                  checked={isNegativeChecked}
                  onChange={() => setIsNegativeChecked(!isNegativeChecked)}
                />
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg justify-between flex flex-row px-4 my-2 gap-4 items-center">
              <p>Tag Teman</p>
              <div className="flex flex-row gap-2 justify-between items-center">
                <span>{money(data?.tagFriend)}</span>
                <Checkbox
                  checked={isTagFriendChecked}
                  onChange={() => setIsTagFriendChecked(!isTagFriendChecked)}
                />
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg justify-between flex flex-row px-4 my-2 gap-4 items-center">
              <p>Like</p>
              <div className="flex flex-row gap-2 justify-between items-center">
                <span>{money(data?.like)}</span>
                <Checkbox
                  checked={isLikeChecked}
                  onChange={() => setIsLikeChecked(!isLikeChecked)}
                />
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg justify-between flex flex-row px-4 my-2 gap-4 items-center">
              <p>Share</p>
              <div className="flex flex-row gap-2 justify-between items-center">
                <span>{money(data?.share)}</span>
                <Checkbox
                  checked={isShareChecked}
                  onChange={() => setIsShareChecked(!isShareChecked)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isAccountChecked &&
            isPositiveChecked &&
            isNegativeChecked &&
            isTagFriendChecked &&
            isLikeChecked &&
            isShareChecked && (
              <button
                className="btn-orange w-full"
                onClick={() => {
                  setIsLoading(true);
                  let req = new SetAsInProgressRequest();
                  req.setOrderId(orderId!);
                  req.setCommentatorAccountId(accountId!);
                  commentatorOrderClient.setAsFinished(
                    req,
                    getToken(),
                    (err, resp) => {
                      setIsLoading(false);
                      if (err) {
                        errorToast(err.message);
                        return;
                      }
                      setModalPresence(false);
                      getDetail();
                      successToast("Absensi Sudah Dikirim");
                    }
                  );
                }}
              >
                Absen
              </button>
            )}
        </Modal.Footer>
      </Modal>
    </CommentatorMid>
  );
};
export default CommentatorOrderDetail;
